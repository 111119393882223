@import '../constants/colors';

.down-arrow-icon {
  width: 0;
  height: 0;
  margin: 8px 5px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid $darkGrey;
}

.plus-icon {
  width: 24px;
  height: 24px;
  margin-right: 9px;
  background-image: url('../../images/plus-icon.svg');
}

.primary-plus-icon {
  width: 24px;
  height: 24px;
  margin-right: 9px;
  background-image: url('../../images/primary-plus-icon.svg');
}

.edit-icon {
  width: 24px;
  height: 24px;
  margin-left: 9px;
  background-image: url('../../images/edit-icon.svg');
}

.left-arrow-icon {
  background-image: url('../../images/left-arrow-icon.svg');
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.success-icon {
  background-image: url('../../images/success-icon.svg');
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.warning-icon {
  background-image: url('../../images/warning-icon.svg');
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.close-icon {
  background-image: url('../../images/close-icon.svg');
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.upload-icon {
  background-image: url('../../images/upload-icon.svg');
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.download-icon {
  background-image: url('../../images/download-icon.svg');
  width: 24px;
  height: 24px;
  margin-right: 24px;
}

.delete-icon {
  background-image: url('../../images/delete-icon.svg');
  width: 24px;
  height: 24px;
}

.filled-delete-icon {
  background-image: url('../../images/filled-delete-icon.svg');
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.upload-file-icon {
  background-image: url('../../images/upload-file-yellow.svg');
  width: 24px;
  height: 24px;
}

.close-grey-icon {
  background-image: url('../../images/close_grey_icon.svg');
  width: 24px;
  height: 24px;
}

.pdf-file-icon {
  background-image: url('../../images/pdf-file-icon.svg');
  width: 102px;
  height: 106px;
  margin-bottom: 4px;
}

.xlxs-file-icon {
  background-image: url('../../images/xlxs-file-icon.svg');
  width: 102px;
  height: 106px;
  margin-bottom: 4px;
}

.eye-icon {
  background-image: url('../../images/eye-icon.svg');
  width: 24px;
  height: 24px;
}

.eye-crossed-out-icon {
  background-image: url('../../images/eye-crossed-out-icon.svg');
  width: 24px;
  height: 24px;
}

.question-icon {
  background-image: url('../../images/question-icon.svg');
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.mp4-file-icon {
  background-image: url('../../images/mp4-file-icon.svg');
  width: 102px;
  height: 106px;
  margin-bottom: 4px;
}

.eml-file-icon {
  background-image: url('../../images/eml-file-icon.svg');
  width: 102px;
  height: 106px;
  margin-bottom: 4px;
}

.filled-close-grey-icon {
  background-image: url('../../images/filled-close-grey-icon.svg');
  width: 24px;
  height: 24px;
}

.dark-left-arrow-icon {
  background-image: url('../../images/dark-left-arrow-icon.svg');
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.white-right-arrow-icon {
  background-image: url('../../images/white-right-arrow-icon.svg');
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.filled-check-icon {
  background-image: url('../../images/filled-check-icon.svg');
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.allowed-icon {
  background-image: url('../../images/filled-check-icon.svg');
  background-size: cover;
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.no-allowed-icon {
  background-image: url('../../images/no-allowed-icon.svg');
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.big-filled-success-icon {
  background-image: url('../../images/big-filled-success-icon.svg');
  width: 48px;
  height: 48px;
}

@import '../constants/colors';

.menu {
  width: 230px;
  min-width: 230px;
  min-height: 100vh;
  background: $white;
  box-shadow: 0 12px 24px rgba(27, 78, 163, 0.04), 0 24px 48px rgba(41, 121, 255, 0.04);
  position: relative;
  display: flex;
  flex-direction: column;

  .menu_main,
  .menu_management {
    .menu_item {
      font-size: 15px;
      line-height: 20px;
      color: $lightBlue;
      padding: 18px 0 18px 56px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none;
      background-repeat: no-repeat;
      background-position: 20px center;

      &:after {
        content: '';
        position: absolute;
        width: 3px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $blue;
        display: none;
        border-radius: 0 5px 5px 0;
      }
      &:hover {
        background-color: rgba(34, 50, 102, 0.1);
        color: $blue;
        font-weight: 700;

        &:after {
          display: block;
        }
      }
    }
  }

  .menu_main {
    .menu_item.eaf_submission,
    .menu_item.request_forms {
      background-image: url('../../images/EAF_submission.svg');

      &:hover {
        background-image: url('../../images/EAF_submission_hover.svg');
      }
    }
    .menu_item.design {
      background-image: url('../../images/design.svg');

      &:hover {
        background-image: url('../../images/design_hover.svg');
      }
    }
    .menu_item.quick_calculator {
      background-image: url('../../images/quick_calculator.svg');

      &:hover {
        background-image: url('../../images/quick_calculator_hover.svg');
      }
    }
    .menu_item.material_calculator {
      background-image: url('../../images/material_calculator.svg');

      &:hover {
        background-image: url('../../images/material_calculator_hover.svg');
      }
    }
    .menu_item.clock_spring_calc {
      background-image: url('../../images/clock_spring_calculator.svg');

      &:hover {
        background-image: url('../../images/clock_spring_calculator_hover.svg');
      }
    }

    & > span {
      background: url('../../images/logo118x51.png') no-repeat 17px 12px;
      height: 71px;
      text-indent: -1000px;
      display: block;
      cursor: pointer;
    }
  }

  .menu_management {
    .menu_item.users {
      background-image: url('../../images/users.svg');

      &:hover {
        background-image: url('../../images/users_hover.svg');
      }
    }
    .menu_item.global_companies {
      background-image: url('../../images/global_companies.svg');

      &:hover {
        background-image: url('../../images/global_companies_hover.svg');
      }
    }
    .menu_item.regional_divisions {
      background-image: url('../../images/regional_divisions.svg');

      &:hover {
        background-image: url('../../images/regional_divisions_hover.svg');
      }
    }
    .menu_item.products {
      background-image: url('../../images/products.svg');

      &:hover {
        background-image: url('../../images/products_hover.svg');
      }
    }
    .menu_item.report {
      background-image: url('../../images/report.svg');

      &:hover {
        background-image: url('../../images/report_hover.svg');
      }
    }

    & > span {
      display: block;
      font-size: 13px;
      line-height: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: $grey;
      border-top: 1px solid $lightGrey2;
      margin: 12px 20px 0 20px;
      padding: 24px 0 12px;
      cursor: default;
    }

    & > span:hover {
      background: none;
    }
  }

  .menu_full_logo {
    position: relative;
    left: 10px;
    margin-bottom: 20px;
    margin-top: auto;

    & > span {
      display: none;
    }

    & > div > div.menu_item {
      background: url('../../images/CSNRI-full-logo.png') no-repeat center top;
      height: 60px;
      background-size: cover;
      width: 200px;
    }
  }

  .menu_loctite_logo {
    position: relative;
    left: 10px;
    margin-bottom: 50px;

    & > span {
      display: none;
    }

    & > div > div.menu_item {
      background: url('../../images/Loctite-logo.png') no-repeat center;
      height: 60px;
      background-size: 175px 33px;
      width: 200px;
    }
  }

  .menu_footer {
    position: relative;
    left: 20px;
    bottom: 16px;

    & > span {
      display: none;
    }
    .menu_item {
      font-size: 13px;
      line-height: 16px;
      color: $grey;
      padding: 4px 0;
      text-decoration: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

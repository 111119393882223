@import '../constants/colors';

header {
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back-link {
      text-decoration: none;
      font-size: 12px;
      color: $blue2;
      display: flex;
      align-items: center;
      font-weight: bold;
    }

    .logged-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: auto;

      .dropdown {
        position: relative;
        display: flex;
        flex-direction: column;

        .dropdown-btn {
          display: flex;
          align-items: center;
          cursor: pointer;
          height: 71px;

          .abbreviation {
            background: $darkYellow;
            font-weight: bold;
            font-size: 15px;
            width: 36px;
            height: 36px;
            border-radius: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
          }
        }
        .dropdown-container {
          display: none;
          color: $darkBlue;
          background: $white;
          padding: 4px;
          box-shadow: 0 4px 16px -2px $darkGrey3;
          border-radius: 4px;
          z-index: 3;

          a {
            text-decoration: none;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding: 12px;
            border-radius: 4px;

            &:hover {
              background: $lightGrey3;
            }
          }
        }

        &:hover {
          .dropdown-container {
            display: flex;
            justify-content: center;
            width: 100%;
            position: absolute;
            right: 0;
            bottom: -40px;
          }

          .down-arrow-icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  h1 {
    margin-bottom: 24px;
  }
}

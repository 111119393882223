@import '../constants/colors';

.toast {
  display: flex;
  margin-left: auto;

  .toast-container {
    display: flex;
    align-items: center;
    padding: 12px;
    box-shadow: 0 4px 24px 0 $darkGrey3;
    border-radius: 4px;

    .toast-button {
      cursor: pointer;
    }
  }

  .success {
    background: $greenLight;
  }

  .error {
    background: $redLight;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
}

h2 {
  margin-bottom: 24px;
}

.wrapper {
  display: flex;
}

label {
  margin-top: 24px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.content {
  flex: 1;
  padding: 0 24px;
}

.invalid-feedback {
  margin-top: 8px;
  display: flex;
}

@import '../constants/colors';

$primary: $blue;

$body-color: $darkBlue;
$body-bg: $lightGrey4;

$font-size-root: 10px;

$font-family-base: Arial;
$font-size-base: 1.5rem;
$line-height-base: 1.334;

$h1-font-size: 3.2rem;
$headings-line-height: 4rem;
$headings-font-weight: 700;

$input-bg: $white;
$input-border-color: $grey2;
$input-padding-y: 9px;
$input-padding-x: 16px;

$btn-font-weight: 700;
$btn-padding-y: 7px;
$btn-padding-x: 18px;

$form-label-margin-bottom: 8px;
$form-label-font-weight: 700;

@import '../constants/colors';

.upload-button,
.add-button {
  background: $blue;
  color: $white;
  padding: 8px 20px 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: auto;

  &:hover {
    color: $white;
  }
}

.cancel-button {
  margin-right: 20px;
  border: 1px solid #22326680;

  &:hover {
    background: $lightGrey3;
  }
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
}

.copy-invitation-link-button,
.status-button,
.resend-button {
  margin-left: 22px;
  display: flex;
  align-items: center;
}

.add-material-size-button {
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  color: $blue;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: $blue;
  }
}
